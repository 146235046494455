import React from "react";
import Head from "next/head";
import { SITE_ROOT_URL, SITE_TITLE } from "../constants/website";
import { TWITTER_USERNAME } from "../constants/sns";
import { useRouter } from "next/router";

interface Props {
  title: string;
  description: string;
  ogpDescription?: string;
  pagePath?: string;
  image: string;
}

export default function SocialMeta({
  title,
  pagePath,
  description,
  ogpDescription,
  image,
}: Props) {
  const router = useRouter();
  return (
    <Head>
      <meta name="twitter:site" content={TWITTER_USERNAME} />
      <meta name="twitter:card" content={"summary_large_image"} />
      <meta property="og:type" content="article" />
      <meta property="og:site_name" content={SITE_TITLE} />
      <meta
        property="og:url"
        content={SITE_ROOT_URL + pagePath ? pagePath : router.pathname}
      />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={ogpDescription || description} />
      <meta property="og:image" content={image} />
    </Head>
  );
}
