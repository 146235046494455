import React, { CSSProperties, ReactNode } from "react";
import { Box } from "@chakra-ui/react";
import LazyImage from "./LazyImage";
import { ImagePlaceholder, OptimizedImage } from "@src/@types/image";

interface Props {
  bgImg: OptimizedImage;
  bgImgPlaceholder?: ImagePlaceholder;
  alt: string;
  children: ReactNode;
  style?: CSSProperties;
  imgStyle?: CSSProperties;
}

export default function BackgroundImageBox({
  bgImg,
  bgImgPlaceholder,
  alt,
  children,
  style,
  imgStyle,
}: Props) {
  return (
    <Box position="relative" h={[300, 500]} style={{ ...style }}>
      <LazyImage
        placeholder={bgImgPlaceholder}
        img={bgImg}
        alt={alt}
        style={{ width: "100%", ...imgStyle }}
      />
      <Box
        display="flex"
        alignItems="center"
        position="absolute"
        bottom="0"
        w="100%"
        h="100%"
        p={2}
        backgroundColor="rgba(0, 0, 0, 0.5)"
        borderRadius={5}
      >
        {children}
      </Box>
    </Box>
  );
}
