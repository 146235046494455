import React from "react";
import { withRouter } from "next/router";
import { WithRouterProps } from "next/dist/client/with-router";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { truncate } from "@src/lib/string";

const structureMapping = {
  media: "メディア",
  tags: "タグ",
  harajuku: "原宿",
  shinjuku: "新宿",
  ebisu: "恵比寿",
  ginza: "銀座",
};

interface Props extends WithRouterProps {
  title: string;
}

export default withRouter(function Breadcrumbs({ router, title }: Props) {
  const paths = router.pathname.split("/").filter((path) => path !== "");
  const breadcrumbItems = paths.map((path, i) => (
    <BreadcrumbItem key={path} isCurrentPage={paths.length === i + 1}>
      <BreadcrumbLink href={"/" + paths.slice(0, i + 1).join("/")}>
        <Text color="gray.600">
          {paths.length === i + 1
            ? truncate(title, 30)
            : structureMapping[path]}
        </Text>
      </BreadcrumbLink>
    </BreadcrumbItem>
  ));
  return (
    <Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.400" />}>
      {breadcrumbItems}
    </Breadcrumb>
  );
});
