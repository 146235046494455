import React from "react";
import Head from "next/head";
import SocialMeta from "./SocialMeta";
import { SITE_TITLE } from "@src/constants/website";

interface Props {
  id: string;
  title: string;
  description: string;
  ogpDescription?: string;
  // set path if the page path is generated dynamically
  pagePath?: string;
  image: string;
  tags: string[];
}

export default function SeoMeta(props: Props) {
  const postTags = props.tags.map((t) => (
    <meta key={t} property="posttag" content={t} />
  ));
  return (
    <>
      <Head>
        <title>
          {props.title} - {SITE_TITLE}
        </title>
        <meta name="id" content={props.id} />
        <meta name="description" content={props.description} />
        {postTags}
      </Head>
      <SocialMeta {...props} />
    </>
  );
}
