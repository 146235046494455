export const commonGoogleMapPinImage = {
  src: require("../../public/images/common/google_map_pin.png"),
  preSrc: require("../../public/images/common/google_map_pin.png"),
};

export const mediaAuthorsMurakamiMasanoriImage = {
  src: require("../../public/images/media/authors/murakami_masanori.jpeg"),
  preSrc: require("../../public/images/media/authors/murakami_masanori.jpeg"),
};

export const ebisuChameFrontImage = {
  src: require("../../public/images/media/ebisu/chame/front.jpg"),
  preSrc: require("../../public/images/media/ebisu/chame/front.jpg"),
};

export const ebisuChameLunchsetImage = {
  src: require("../../public/images/media/ebisu/chame/lunchset.jpg"),
  preSrc: require("../../public/images/media/ebisu/chame/lunchset.jpg"),
};

export const ebisuChameLunchsoupImage = {
  src: require("../../public/images/media/ebisu/chame/lunchsoup.jpg"),
  preSrc: require("../../public/images/media/ebisu/chame/lunchsoup.jpg"),
};

export const ebisuShintoukiTopShintoukiImage = {
  src: require("../../public/images/media/ebisu/shintouki/top_shintouki.jpg"),
  preSrc: require("../../public/images/media/ebisu/shintouki/top_shintouki.jpg"),
};

export const mediaForRestaurantFourServiceImage = {
  src: require("../../public/images/media/for_restaurant/four_service.jpg"),
  preSrc: require("../../public/images/media/for_restaurant/four_service.jpg"),
};

export const ginzaAndhraAndhraCurryImage = {
  src: require("../../public/images/media/ginza/andhra/andhra_curry.jpg"),
  preSrc: require("../../public/images/media/ginza/andhra/andhra_curry.jpg"),
};

export const ginzaAndhraAndhraFrontImage = {
  src: require("../../public/images/media/ginza/andhra/andhra_front.jpg"),
  preSrc: require("../../public/images/media/ginza/andhra/andhra_front.jpg"),
};

export const ginzaAndhraAndhraNanImage = {
  src: require("../../public/images/media/ginza/andhra/andhra_nan.jpg"),
  preSrc: require("../../public/images/media/ginza/andhra/andhra_nan.jpg"),
};

export const ginzaAndhraAndratopImage = {
  src: require("../../public/images/media/ginza/andhra/andratop.jpg"),
  preSrc: require("../../public/images/media/ginza/andhra/andratop.jpg"),
};

export const ginzaGurgaonGurgaonTopImage = {
  src: require("../../public/images/media/ginza/gurgaon/gurgaon_top.jpg"),
  preSrc: require("../../public/images/media/ginza/gurgaon/gurgaon_top.jpg"),
};

export const ginzaHachigoCounterImage = {
  src: require("../../public/images/media/ginza/hachigo/counter.jpg"),
  preSrc: require("../../public/images/media/ginza/hachigo/counter.jpg"),
};

export const ginzaHachigoEatingImage = {
  src: require("../../public/images/media/ginza/hachigo/eating.jpg"),
  preSrc: require("../../public/images/media/ginza/hachigo/eating.jpg"),
};

export const ginzaHachigoFrontImage = {
  src: require("../../public/images/media/ginza/hachigo/front.jpg"),
  preSrc: require("../../public/images/media/ginza/hachigo/front.jpg"),
};

export const ginzaHachigoHachigoramenImage = {
  src: require("../../public/images/media/ginza/hachigo/hachigoramen.jpg"),
  preSrc: require("../../public/images/media/ginza/hachigo/hachigoramen.jpg"),
};

export const ginzaHachigoTopImage = {
  src: require("../../public/images/media/ginza/hachigo/top.jpg"),
  preSrc: require("../../public/images/media/ginza/hachigo/top.jpg"),
};

export const ginzaKazamiKzamiTopImage = {
  src: require("../../public/images/media/ginza/kazami/kzami_top.jpg"),
  preSrc: require("../../public/images/media/ginza/kazami/kzami_top.jpg"),
};

export const ginzaKobikichoKobikichoInImage = {
  src: require("../../public/images/media/ginza/kobikicho/kobikicho_in.jpg"),
  preSrc: require("../../public/images/media/ginza/kobikicho/kobikicho_in.jpg"),
};

export const ginzaKobikichoKobikichoMenuImage = {
  src: require("../../public/images/media/ginza/kobikicho/kobikicho_menu.jpg"),
  preSrc: require("../../public/images/media/ginza/kobikicho/kobikicho_menu.jpg"),
};

export const ginzaKobikichoKobikichoSobaImage = {
  src: require("../../public/images/media/ginza/kobikicho/kobikicho_soba.jpg"),
  preSrc: require("../../public/images/media/ginza/kobikicho/kobikicho_soba.jpg"),
};

export const ginzaLunchBakaryImage = {
  src: require("../../public/images/media/ginza/lunch/bakary.jpg"),
  preSrc: require("../../public/images/media/ginza/lunch/bakary.jpg"),
};

export const ginzaLunchCheeseImage = {
  src: require("../../public/images/media/ginza/lunch/cheese.jpg"),
  preSrc: require("../../public/images/media/ginza/lunch/cheese.jpg"),
};

export const ginzaLunchFront0Image = {
  src: require("../../public/images/media/ginza/lunch/front_0.jpg"),
  preSrc: require("../../public/images/media/ginza/lunch/front_0.jpg"),
};

export const ginzaLunchFruitSandImage = {
  src: require("../../public/images/media/ginza/lunch/fruit_sand.jpg"),
  preSrc: require("../../public/images/media/ginza/lunch/fruit_sand.jpg"),
};

export const ginzaLunchGurugaonCurryImage = {
  src: require("../../public/images/media/ginza/lunch/gurugaon_curry.jpg"),
  preSrc: require("../../public/images/media/ginza/lunch/gurugaon_curry.jpg"),
};

export const ginzaLunchPizzaImage = {
  src: require("../../public/images/media/ginza/lunch/pizza.jpg"),
  preSrc: require("../../public/images/media/ginza/lunch/pizza.jpg"),
};

export const ginzaLunchRamenImage = {
  src: require("../../public/images/media/ginza/lunch/ramen.jpg"),
  preSrc: require("../../public/images/media/ginza/lunch/ramen.jpg"),
};

export const ginzaLunchVomeroImage = {
  src: require("../../public/images/media/ginza/lunch/vomero.jpg"),
  preSrc: require("../../public/images/media/ginza/lunch/vomero.jpg"),
};

export const ginzaLunchYudukamiyaImage = {
  src: require("../../public/images/media/ginza/lunch/yudukamiya.jpg"),
  preSrc: require("../../public/images/media/ginza/lunch/yudukamiya.jpg"),
};

export const ginzaMugitooliveAburasobaImage = {
  src: require("../../public/images/media/ginza/mugitoolive/aburasoba.jpg"),
  preSrc: require("../../public/images/media/ginza/mugitoolive/aburasoba.jpg"),
};

export const ginzaMugitooliveMugitooliveFrontImage = {
  src: require("../../public/images/media/ginza/mugitoolive/mugitoolive_front.jpg"),
  preSrc: require("../../public/images/media/ginza/mugitoolive/mugitoolive_front.jpg"),
};

export const ginzaMugitooliveMugitooliveInImage = {
  src: require("../../public/images/media/ginza/mugitoolive/mugitoolive_in.jpg"),
  preSrc: require("../../public/images/media/ginza/mugitoolive/mugitoolive_in.jpg"),
};

export const ginzaMugitooliveMugitooliveMichelinImage = {
  src: require("../../public/images/media/ginza/mugitoolive/mugitoolive_michelin.jpg"),
  preSrc: require("../../public/images/media/ginza/mugitoolive/mugitoolive_michelin.jpg"),
};

export const ginzaMugitooliveMugitooliveTsukemenImage = {
  src: require("../../public/images/media/ginza/mugitoolive/mugitoolive_tsukemen.jpg"),
  preSrc: require("../../public/images/media/ginza/mugitoolive/mugitoolive_tsukemen.jpg"),
};

export const ginzaNileNileCurryImage = {
  src: require("../../public/images/media/ginza/nile/nile_curry.jpg"),
  preSrc: require("../../public/images/media/ginza/nile/nile_curry.jpg"),
};

export const ginzaNileNileInImage = {
  src: require("../../public/images/media/ginza/nile/nile_in.jpg"),
  preSrc: require("../../public/images/media/ginza/nile/nile_in.jpg"),
};

export const ginzaNileNileMuttonCurryImage = {
  src: require("../../public/images/media/ginza/nile/nile_mutton_curry.jpg"),
  preSrc: require("../../public/images/media/ginza/nile/nile_mutton_curry.jpg"),
};

export const ginzaPizzaPizzatopImage = {
  src: require("../../public/images/media/ginza/pizza/pizzatop.jpg"),
  preSrc: require("../../public/images/media/ginza/pizza/pizzatop.jpg"),
};

export const ginzaVomeroMarinalaImage = {
  src: require("../../public/images/media/ginza/vomero/marinala.jpg"),
  preSrc: require("../../public/images/media/ginza/vomero/marinala.jpg"),
};

export const ginzaVomeroPizzagamaImage = {
  src: require("../../public/images/media/ginza/vomero/pizzagama.jpg"),
  preSrc: require("../../public/images/media/ginza/vomero/pizzagama.jpg"),
};

export const ginzaVomeroVomeroTopImage = {
  src: require("../../public/images/media/ginza/vomero/vomero_top.jpg"),
  preSrc: require("../../public/images/media/ginza/vomero/vomero_top.jpg"),
};

export const ginzaYouYouCoffeeImage = {
  src: require("../../public/images/media/ginza/you/you_coffee.jpg"),
  preSrc: require("../../public/images/media/ginza/you/you_coffee.jpg"),
};

export const ginzaYouYouFrontImage = {
  src: require("../../public/images/media/ginza/you/you_front.jpg"),
  preSrc: require("../../public/images/media/ginza/you/you_front.jpg"),
};

export const ginzaYouYouLineImage = {
  src: require("../../public/images/media/ginza/you/you_line.jpg"),
  preSrc: require("../../public/images/media/ginza/you/you_line.jpg"),
};

export const ginzaYouYouOmuriceImage = {
  src: require("../../public/images/media/ginza/you/you_omurice.jpg"),
  preSrc: require("../../public/images/media/ginza/you/you_omurice.jpg"),
};

export const mediaGotandaYazawaInsideImage = {
  src: require("../../public/images/media/gotanda/yazawa_inside.jpg"),
  preSrc: require("../../public/images/media/gotanda/yazawa_inside.jpg"),
};

export const mediaGotandaYazawaLunchboxImage = {
  src: require("../../public/images/media/gotanda/yazawa_lunchbox.jpg"),
  preSrc: require("../../public/images/media/gotanda/yazawa_lunchbox.jpg"),
};

export const mediaGotandaYazawaTopImage = {
  src: require("../../public/images/media/gotanda/yazawa_top.jpg"),
  preSrc: require("../../public/images/media/gotanda/yazawa_top.jpg"),
};

export const harajukuAfuriAfuriTopImage = {
  src: require("../../public/images/media/harajuku/afuri/afuri_top.jpg"),
  preSrc: require("../../public/images/media/harajuku/afuri/afuri_top.jpg"),
};

export const harajukuBreadHarajukuLunchBreadImage = {
  src: require("../../public/images/media/harajuku/bread/harajuku_lunch_bread.jpg"),
  preSrc: require("../../public/images/media/harajuku/bread/harajuku_lunch_bread.jpg"),
};

export const harajukuCafeCoolHarajukuCafeCoolImage = {
  src: require("../../public/images/media/harajuku/cafe_cool/harajuku_cafe_cool.jpg"),
  preSrc: require("../../public/images/media/harajuku/cafe_cool/harajuku_cafe_cool.jpg"),
};

export const harajukuCafeHarajukuGyoretsuCafeImage = {
  src: require("../../public/images/media/harajuku/cafe/harajuku_gyoretsu_cafe.jpg"),
  preSrc: require("../../public/images/media/harajuku/cafe/harajuku_gyoretsu_cafe.jpg"),
};

export const harajukuCatStreetHarajukuCatstreetLunchImage = {
  src: require("../../public/images/media/harajuku/cat_street/harajuku_catstreet_lunch.jpg"),
  preSrc: require("../../public/images/media/harajuku/cat_street/harajuku_catstreet_lunch.jpg"),
};

export const harajukuChavatyTakeoutChavatyTakeoutTopImage = {
  src: require("../../public/images/media/harajuku/chavaty_takeout/chavaty_takeout_top.jpg"),
  preSrc: require("../../public/images/media/harajuku/chavaty_takeout/chavaty_takeout_top.jpg"),
};

export const harajukuChavatyChavatyTopImage = {
  src: require("../../public/images/media/harajuku/chavaty/chavaty_top.jpg"),
  preSrc: require("../../public/images/media/harajuku/chavaty/chavaty_top.jpg"),
};

export const harajukuCrisscrossMorningCrisscrossMorningImage = {
  src: require("../../public/images/media/harajuku/crisscross_morning/crisscross_morning.jpg"),
  preSrc: require("../../public/images/media/harajuku/crisscross_morning/crisscross_morning.jpg"),
};

export const harajukuCrisscrossCrisssrossTopImage = {
  src: require("../../public/images/media/harajuku/crisscross/crisssross_top.jpg"),
  preSrc: require("../../public/images/media/harajuku/crisscross/crisssross_top.jpg"),
};

export const harajukuCurryHyakuMeitenCurry3TopImage = {
  src: require("../../public/images/media/harajuku/curry_hyaku_meiten/curry_3_top.jpg"),
  preSrc: require("../../public/images/media/harajuku/curry_hyaku_meiten/curry_3_top.jpg"),
};

export const harajukuDaigomiHarajukuLunchCheeseImage = {
  src: require("../../public/images/media/harajuku/daigomi/harajuku_lunch_cheese.jpg"),
  preSrc: require("../../public/images/media/harajuku/daigomi/harajuku_lunch_cheese.jpg"),
};

export const harajukuDatingHarajukuDateLunchImage = {
  src: require("../../public/images/media/harajuku/dating/harajuku_date_lunch.jpg"),
  preSrc: require("../../public/images/media/harajuku/dating/harajuku_date_lunch.jpg"),
};

export const harajukuDeliveryPizzaHarajukuPizzaTakeoutImage = {
  src: require("../../public/images/media/harajuku/delivery_pizza/harajuku_pizza_takeout.jpg"),
  preSrc: require("../../public/images/media/harajuku/delivery_pizza/harajuku_pizza_takeout.jpg"),
};

export const harajukuDeliveryHarajukuTakeoutImage = {
  src: require("../../public/images/media/harajuku/delivery/harajuku_takeout.jpg"),
  preSrc: require("../../public/images/media/harajuku/delivery/harajuku_takeout.jpg"),
};

export const harajukuEkichikaHarajukuStationNearImage = {
  src: require("../../public/images/media/harajuku/ekichika/harajuku_station_near.jpg"),
  preSrc: require("../../public/images/media/harajuku/ekichika/harajuku_station_near.jpg"),
};

export const harajukuFellowsTopImage = {
  src: require("../../public/images/media/harajuku/fellows/top.jpg"),
  preSrc: require("../../public/images/media/harajuku/fellows/top.jpg"),
};

export const harajukuGatsuriHarajukuGattsuriTopImage = {
  src: require("../../public/images/media/harajuku/gatsuri/harajuku_gattsuri-top.jpg"),
  preSrc: require("../../public/images/media/harajuku/gatsuri/harajuku_gattsuri-top.jpg"),
};

export const harajukuGoldenBrownGoldenburgerTopImage = {
  src: require("../../public/images/media/harajuku/golden_brown/goldenburger_top.jpg"),
  preSrc: require("../../public/images/media/harajuku/golden_brown/goldenburger_top.jpg"),
};

export const harajukuHawaiianPancakeCafeHaleiwaApperanceImage = {
  src: require("../../public/images/media/harajuku/hawaiian_pancake/cafe_haleiwa_apperance.jpg"),
  preSrc: require("../../public/images/media/harajuku/hawaiian_pancake/cafe_haleiwa_apperance.jpg"),
};

export const harajukuHawaiianPancakeCafeHaleiwaPancakeImage = {
  src: require("../../public/images/media/harajuku/hawaiian_pancake/cafe_haleiwa_pancake.jpg"),
  preSrc: require("../../public/images/media/harajuku/hawaiian_pancake/cafe_haleiwa_pancake.jpg"),
};

export const harajukuHawaiianPancakeTopImage = {
  src: require("../../public/images/media/harajuku/hawaiian_pancake/top.jpg"),
  preSrc: require("../../public/images/media/harajuku/hawaiian_pancake/top.jpg"),
};

export const harajukuHitoriHarajukuAloneLunchImage = {
  src: require("../../public/images/media/harajuku/hitori/harajuku_alone_lunch.jpg"),
  preSrc: require("../../public/images/media/harajuku/hitori/harajuku_alone_lunch.jpg"),
};

export const harajukuInitialInitialImage = {
  src: require("../../public/images/media/harajuku/initial/initial.jpg"),
  preSrc: require("../../public/images/media/harajuku/initial/initial.jpg"),
};

export const harajukuInstaPancakeInstaTopImage = {
  src: require("../../public/images/media/harajuku/insta_pancake/insta_top.jpg"),
  preSrc: require("../../public/images/media/harajuku/insta_pancake/insta_top.jpg"),
};

export const harajukuItalianItalianImage = {
  src: require("../../public/images/media/harajuku/italian/italian.jpg"),
  preSrc: require("../../public/images/media/harajuku/italian/italian.jpg"),
};

export const harajukuKakuregaHarajukuHideoutImage = {
  src: require("../../public/images/media/harajuku/kakurega/harajuku_hideout.jpg"),
  preSrc: require("../../public/images/media/harajuku/kakurega/harajuku_hideout.jpg"),
};

export const harajukuKevelosKevelosTopImage = {
  src: require("../../public/images/media/harajuku/kevelos/kevelos_top.jpg"),
  preSrc: require("../../public/images/media/harajuku/kevelos/kevelos_top.jpg"),
};

export const harajukuKintanKintanImage = {
  src: require("../../public/images/media/harajuku/kintan/kintan.jpg"),
  preSrc: require("../../public/images/media/harajuku/kintan/kintan.jpg"),
};

export const harajukuKitoMuzutoTsuchitoWoodTopImage = {
  src: require("../../public/images/media/harajuku/kito_muzuto_tsuchito/wood_top.jpg"),
  preSrc: require("../../public/images/media/harajuku/kito_muzuto_tsuchito/wood_top.jpg"),
};

export const harajukuLittlePoolCoffeeLittlePoolCoffeeImage = {
  src: require("../../public/images/media/harajuku/little_pool_coffee/little_pool_coffee.jpg"),
  preSrc: require("../../public/images/media/harajuku/little_pool_coffee/little_pool_coffee.jpg"),
};

export const harajukuLittleRimaHumburgerSteakImage = {
  src: require("../../public/images/media/harajuku/little_rima/humburger-steak.jpg"),
  preSrc: require("../../public/images/media/harajuku/little_rima/humburger-steak.jpg"),
};

export const harajukuLunchLateHarajukuLateLunchImage = {
  src: require("../../public/images/media/harajuku/lunch_late/harajuku_late_lunch.jpg"),
  preSrc: require("../../public/images/media/harajuku/lunch_late/harajuku_late_lunch.jpg"),
};

export const harajukuLunchPopularHarajukuFamousLunchImage = {
  src: require("../../public/images/media/harajuku/lunch_popular/harajuku_famous_lunch.jpg"),
  preSrc: require("../../public/images/media/harajuku/lunch_popular/harajuku_famous_lunch.jpg"),
};

export const harajukuLunchSundayHarajukuSundayKunchImage = {
  src: require("../../public/images/media/harajuku/lunch_sunday/harajuku_sunday_kunch.jpg"),
  preSrc: require("../../public/images/media/harajuku/lunch_sunday/harajuku_sunday_kunch.jpg"),
};

export const harajukuLunchTopImage = {
  src: require("../../public/images/media/harajuku/lunch/top.jpg"),
  preSrc: require("../../public/images/media/harajuku/lunch/top.jpg"),
};

export const harajukuMicasadecoMenuMicasadecoMenuTopImage = {
  src: require("../../public/images/media/harajuku/micasadeco_menu/micasadeco_menu_top.jpg"),
  preSrc: require("../../public/images/media/harajuku/micasadeco_menu/micasadeco_menu_top.jpg"),
};

export const harajukuMicasadecoTopMicasadecoImage = {
  src: require("../../public/images/media/harajuku/micasadeco/top_micasadeco.jpg"),
  preSrc: require("../../public/images/media/harajuku/micasadeco/top_micasadeco.jpg"),
};

export const harajukuMinoringoAppearanceOutImage = {
  src: require("../../public/images/media/harajuku/minoringo/appearance_out.jpg"),
  preSrc: require("../../public/images/media/harajuku/minoringo/appearance_out.jpg"),
};

export const harajukuMinoringoCheeseKiymaCurryImage = {
  src: require("../../public/images/media/harajuku/minoringo/cheese_kiyma_curry.jpg"),
  preSrc: require("../../public/images/media/harajuku/minoringo/cheese_kiyma_curry.jpg"),
};

export const harajukuMinoringoKiymaCurryMenuImage = {
  src: require("../../public/images/media/harajuku/minoringo/kiyma_curry_menu.jpg"),
  preSrc: require("../../public/images/media/harajuku/minoringo/kiyma_curry_menu.jpg"),
};

export const harajukuMinoringoKiymaCurryImage = {
  src: require("../../public/images/media/harajuku/minoringo/kiyma_curry.jpg"),
  preSrc: require("../../public/images/media/harajuku/minoringo/kiyma_curry.jpg"),
};

export const harajukuMinoringoSpecialImage = {
  src: require("../../public/images/media/harajuku/minoringo/special.jpg"),
  preSrc: require("../../public/images/media/harajuku/minoringo/special.jpg"),
};

export const harajukuMinoringoTopImage = {
  src: require("../../public/images/media/harajuku/minoringo/top.jpeg"),
  preSrc: require("../../public/images/media/harajuku/minoringo/top.jpeg"),
};

export const harajukuMokubazaMenuMokubazaMenuTopImage = {
  src: require("../../public/images/media/harajuku/mokubaza_menu/mokubaza_menu_top.jpg"),
  preSrc: require("../../public/images/media/harajuku/mokubaza_menu/mokubaza_menu_top.jpg"),
};

export const harajukuMokubazaCurryKiymaCurryGrillImage = {
  src: require("../../public/images/media/harajuku/mokubaza/curry_kiyma_curry_grill.jpeg"),
  preSrc: require("../../public/images/media/harajuku/mokubaza/curry_kiyma_curry_grill.jpeg"),
};

export const harajukuMokubazaKawashimaImage = {
  src: require("../../public/images/media/harajuku/mokubaza/kawashima.jpg"),
  preSrc: require("../../public/images/media/harajuku/mokubaza/kawashima.jpg"),
};

export const harajukuMokubazaKiyamaCurryAbocadoImage = {
  src: require("../../public/images/media/harajuku/mokubaza/kiyama_curry_abocado.jpg"),
  preSrc: require("../../public/images/media/harajuku/mokubaza/kiyama_curry_abocado.jpg"),
};

export const harajukuMokubazaKiymaCurryImage = {
  src: require("../../public/images/media/harajuku/mokubaza/kiyma_curry.jpg"),
  preSrc: require("../../public/images/media/harajuku/mokubaza/kiyma_curry.jpg"),
};

export const harajukuMokubazaMokubazaAppearanceImage = {
  src: require("../../public/images/media/harajuku/mokubaza/mokubaza-appearance.png"),
  preSrc: require("../../public/images/media/harajuku/mokubaza/mokubaza-appearance.png"),
};

export const harajukuMokubazaMokubazaFoodImage = {
  src: require("../../public/images/media/harajuku/mokubaza/mokubaza-food.png"),
  preSrc: require("../../public/images/media/harajuku/mokubaza/mokubaza-food.png"),
};

export const harajukuMokubazaTakahashiminamiImage = {
  src: require("../../public/images/media/harajuku/mokubaza/takahashiminami.jpg"),
  preSrc: require("../../public/images/media/harajuku/mokubaza/takahashiminami.jpg"),
};

export const harajukuMokubazaTopImage = {
  src: require("../../public/images/media/harajuku/mokubaza/top.jpg"),
  preSrc: require("../../public/images/media/harajuku/mokubaza/top.jpg"),
};

export const harajukuNapreAppearanceOutImage = {
  src: require("../../public/images/media/harajuku/napre/appearance_out.jpeg"),
  preSrc: require("../../public/images/media/harajuku/napre/appearance_out.jpeg"),
};

export const harajukuNapreMargelitaImage = {
  src: require("../../public/images/media/harajuku/napre/margelita.jpg"),
  preSrc: require("../../public/images/media/harajuku/napre/margelita.jpg"),
};

export const harajukuNapreMixvegetablesImage = {
  src: require("../../public/images/media/harajuku/napre/mixvegetables.jpg"),
  preSrc: require("../../public/images/media/harajuku/napre/mixvegetables.jpg"),
};

export const harajukuNaprePizzaIoroImage = {
  src: require("../../public/images/media/harajuku/napre/pizza_ioro.jpg"),
  preSrc: require("../../public/images/media/harajuku/napre/pizza_ioro.jpg"),
};

export const harajukuNaprePizzaKalinImage = {
  src: require("../../public/images/media/harajuku/napre/pizza_kalin.jpg"),
  preSrc: require("../../public/images/media/harajuku/napre/pizza_kalin.jpg"),
};

export const harajukuNaprePizzaMakeImage = {
  src: require("../../public/images/media/harajuku/napre/pizza_make.jpg"),
  preSrc: require("../../public/images/media/harajuku/napre/pizza_make.jpg"),
};

export const harajukuNapreQuatroImage = {
  src: require("../../public/images/media/harajuku/napre/quatro.jpg"),
  preSrc: require("../../public/images/media/harajuku/napre/quatro.jpg"),
};

export const harajukuNapreSeafoodImage = {
  src: require("../../public/images/media/harajuku/napre/seafood.jpg"),
  preSrc: require("../../public/images/media/harajuku/napre/seafood.jpg"),
};

export const harajukuNapreTopImage = {
  src: require("../../public/images/media/harajuku/napre/top.jpg"),
  preSrc: require("../../public/images/media/harajuku/napre/top.jpg"),
};

export const harajukuNapreVegetablesImage = {
  src: require("../../public/images/media/harajuku/napre/vegetables.jpg"),
  preSrc: require("../../public/images/media/harajuku/napre/vegetables.jpg"),
};

export const harajukuNoodleStandTokyoMenuNoodlestandMenuTopImage = {
  src: require("../../public/images/media/harajuku/noodle_stand_tokyo_menu/noodlestand_menu_top.jpg"),
  preSrc: require("../../public/images/media/harajuku/noodle_stand_tokyo_menu/noodlestand_menu_top.jpg"),
};

export const harajukuNoodleStandTokyoNoodleStandTopImage = {
  src: require("../../public/images/media/harajuku/noodle_stand_tokyo/noodle_stand_top.jpg"),
  preSrc: require("../../public/images/media/harajuku/noodle_stand_tokyo/noodle_stand_top.jpg"),
};

export const harajukuNoodleStandTokyoRiceImage = {
  src: require("../../public/images/media/harajuku/noodle_stand_tokyo/rice.jpg"),
  preSrc: require("../../public/images/media/harajuku/noodle_stand_tokyo/rice.jpg"),
};

export const harajukuOmotesandoHyakuMeitenOmotesando3BurgerImage = {
  src: require("../../public/images/media/harajuku/omotesando_hyaku_meiten/omotesando_3_burger.jpg"),
  preSrc: require("../../public/images/media/harajuku/omotesando_hyaku_meiten/omotesando_3_burger.jpg"),
};

export const harajukuOrganicHarajukuOrganicTopImage = {
  src: require("../../public/images/media/harajuku/organic/harajuku_organic_top.jpg"),
  preSrc: require("../../public/images/media/harajuku/organic/harajuku_organic_top.jpg"),
};

export const harajukuPancakeLunchPancakeLunchImage = {
  src: require("../../public/images/media/harajuku/pancake_lunch/pancake_lunch.jpg"),
  preSrc: require("../../public/images/media/harajuku/pancake_lunch/pancake_lunch.jpg"),
};

export const harajukuPancakePopularPancakeLunchImage = {
  src: require("../../public/images/media/harajuku/pancake_popular/pancake_lunch.jpg"),
  preSrc: require("../../public/images/media/harajuku/pancake_popular/pancake_lunch.jpg"),
};

export const harajukuPancakeTopImage = {
  src: require("../../public/images/media/harajuku/pancake/top.jpg"),
  preSrc: require("../../public/images/media/harajuku/pancake/top.jpg"),
};

export const harajukuRainbowPancakeMenuRainbowMenuTopImage = {
  src: require("../../public/images/media/harajuku/rainbow_pancake_menu/rainbow_menu_top.jpg"),
  preSrc: require("../../public/images/media/harajuku/rainbow_pancake_menu/rainbow_menu_top.jpg"),
};

export const harajukuRamenTopImage = {
  src: require("../../public/images/media/harajuku/ramen/top.jpeg"),
  preSrc: require("../../public/images/media/harajuku/ramen/top.jpeg"),
};

export const harajukuRaninbowPancakeTopImage = {
  src: require("../../public/images/media/harajuku/raninbow_pancake/top.jpg"),
  preSrc: require("../../public/images/media/harajuku/raninbow_pancake/top.jpg"),
};

export const harajukuReasonableApperanceRamenImage = {
  src: require("../../public/images/media/harajuku/reasonable/apperance_ramen.jpg"),
  preSrc: require("../../public/images/media/harajuku/reasonable/apperance_ramen.jpg"),
};

export const harajukuReasonableCoconutsRamenImage = {
  src: require("../../public/images/media/harajuku/reasonable/coconuts_ramen.jpg"),
  preSrc: require("../../public/images/media/harajuku/reasonable/coconuts_ramen.jpg"),
};

export const harajukuReasonableMinoringoAppearanceOutImage = {
  src: require("../../public/images/media/harajuku/reasonable/minoringo_appearance_out.jpg"),
  preSrc: require("../../public/images/media/harajuku/reasonable/minoringo_appearance_out.jpg"),
};

export const harajukuReasonableMinoringoCurryImage = {
  src: require("../../public/images/media/harajuku/reasonable/minoringo_curry.jpg"),
  preSrc: require("../../public/images/media/harajuku/reasonable/minoringo_curry.jpg"),
};

export const harajukuReasonablePastaApperanceImage = {
  src: require("../../public/images/media/harajuku/reasonable/pasta_apperance.jpg"),
  preSrc: require("../../public/images/media/harajuku/reasonable/pasta_apperance.jpg"),
};

export const harajukuReasonablePastaImage = {
  src: require("../../public/images/media/harajuku/reasonable/pasta.jpg"),
  preSrc: require("../../public/images/media/harajuku/reasonable/pasta.jpg"),
};

export const harajukuReasonablePizzaApperanceImage = {
  src: require("../../public/images/media/harajuku/reasonable/pizza_apperance.jpg"),
  preSrc: require("../../public/images/media/harajuku/reasonable/pizza_apperance.jpg"),
};

export const harajukuReasonablePizzaMarinalaImage = {
  src: require("../../public/images/media/harajuku/reasonable/pizza_marinala.jpg"),
  preSrc: require("../../public/images/media/harajuku/reasonable/pizza_marinala.jpg"),
};

export const harajukuReasonableSmithRamenImage = {
  src: require("../../public/images/media/harajuku/reasonable/smith_ramen.jpg"),
  preSrc: require("../../public/images/media/harajuku/reasonable/smith_ramen.jpg"),
};

export const harajukuReasonableSmithTsukuneImage = {
  src: require("../../public/images/media/harajuku/reasonable/smith_tsukune.jpeg"),
  preSrc: require("../../public/images/media/harajuku/reasonable/smith_tsukune.jpeg"),
};

export const harajukuReasonableTopImage = {
  src: require("../../public/images/media/harajuku/reasonable/top.jpeg"),
  preSrc: require("../../public/images/media/harajuku/reasonable/top.jpeg"),
};

export const harajukuReasonableVegetablesImage = {
  src: require("../../public/images/media/harajuku/reasonable/vegetables.jpeg"),
  preSrc: require("../../public/images/media/harajuku/reasonable/vegetables.jpeg"),
};

export const harajukuRizlaboRizlaboTopImage = {
  src: require("../../public/images/media/harajuku/rizlabo/rizlabo_top.jpg"),
  preSrc: require("../../public/images/media/harajuku/rizlabo/rizlabo_top.jpg"),
};

export const harajukuRyunokoRyuunokoPaymentImage = {
  src: require("../../public/images/media/harajuku/ryunoko/ryuunoko_payment.jpg"),
  preSrc: require("../../public/images/media/harajuku/ryunoko/ryuunoko_payment.jpg"),
};

export const harajukuRyunokoRyuunokoTopImage = {
  src: require("../../public/images/media/harajuku/ryunoko/ryuunoko_top.jpg"),
  preSrc: require("../../public/images/media/harajuku/ryunoko/ryuunoko_top.jpg"),
};

export const harajukuRyunokoRyuunokoWaitingImage = {
  src: require("../../public/images/media/harajuku/ryunoko/ryuunoko_waiting.jpg"),
  preSrc: require("../../public/images/media/harajuku/ryunoko/ryuunoko_waiting.jpg"),
};

export const harajukuShiniseHarajukuShiniseTopImage = {
  src: require("../../public/images/media/harajuku/shinise/harajuku_shinise_top.jpg"),
  preSrc: require("../../public/images/media/harajuku/shinise/harajuku_shinise_top.jpg"),
};

export const harajukuSyudanSyudanImage = {
  src: require("../../public/images/media/harajuku/syudan/syudan.jpg"),
  preSrc: require("../../public/images/media/harajuku/syudan/syudan.jpg"),
};

export const harajukuTakeoutHyakuMeitenTakeout100TopImage = {
  src: require("../../public/images/media/harajuku/takeout_hyaku_meiten/takeout_100_top.jpg"),
  preSrc: require("../../public/images/media/harajuku/takeout_hyaku_meiten/takeout_100_top.jpg"),
};

export const harajukuTakeoutPancakeTakeoutPancakeTopImage = {
  src: require("../../public/images/media/harajuku/takeout_pancake/takeout_pancake_top.jpg"),
  preSrc: require("../../public/images/media/harajuku/takeout_pancake/takeout_pancake_top.jpg"),
};

export const harajukuTakeoutSaladaOmotesandoSaladLunchImage = {
  src: require("../../public/images/media/harajuku/takeout_salada/omotesando_salad_lunch.jpg"),
  preSrc: require("../../public/images/media/harajuku/takeout_salada/omotesando_salad_lunch.jpg"),
};

export const harajukuTakeoutBagDrinkImage = {
  src: require("../../public/images/media/harajuku/takeout/bag_drink.jpg"),
  preSrc: require("../../public/images/media/harajuku/takeout/bag_drink.jpg"),
};

export const harajukuTakeoutBreadImage = {
  src: require("../../public/images/media/harajuku/takeout/bread.jpg"),
  preSrc: require("../../public/images/media/harajuku/takeout/bread.jpg"),
};

export const harajukuTakeoutBurgerTakeoutImage = {
  src: require("../../public/images/media/harajuku/takeout/burger_takeout.jpg"),
  preSrc: require("../../public/images/media/harajuku/takeout/burger_takeout.jpg"),
};

export const harajukuTakeoutKisurinTaketoutImage = {
  src: require("../../public/images/media/harajuku/takeout/kisurin_taketout.jpg"),
  preSrc: require("../../public/images/media/harajuku/takeout/kisurin_taketout.jpg"),
};

export const harajukuTakeoutKisurinAppearanceImage = {
  src: require("../../public/images/media/harajuku/takeout/kisurin-appearance.png"),
  preSrc: require("../../public/images/media/harajuku/takeout/kisurin-appearance.png"),
};

export const harajukuTakeoutMokubazaCurryImage = {
  src: require("../../public/images/media/harajuku/takeout/mokubaza_curry.jpg"),
  preSrc: require("../../public/images/media/harajuku/takeout/mokubaza_curry.jpg"),
};

export const harajukuTakeoutMokubazaAppearanceImage = {
  src: require("../../public/images/media/harajuku/takeout/mokubaza-appearance.png"),
  preSrc: require("../../public/images/media/harajuku/takeout/mokubaza-appearance.png"),
};

export const harajukuTakeoutNaprePizzaKalinImage = {
  src: require("../../public/images/media/harajuku/takeout/napre_pizza_kalin.jpg"),
  preSrc: require("../../public/images/media/harajuku/takeout/napre_pizza_kalin.jpg"),
};

export const harajukuTakeoutNaprePizzaImage = {
  src: require("../../public/images/media/harajuku/takeout/napre_pizza.jpg"),
  preSrc: require("../../public/images/media/harajuku/takeout/napre_pizza.jpg"),
};

export const harajukuTakeoutTopImage = {
  src: require("../../public/images/media/harajuku/takeout/top.jpg"),
  preSrc: require("../../public/images/media/harajuku/takeout/top.jpg"),
};

export const harajukuTamawaraiMenuTamawaraiMenuTopImage = {
  src: require("../../public/images/media/harajuku/tamawarai_menu/tamawarai_menu_top.jpg"),
  preSrc: require("../../public/images/media/harajuku/tamawarai_menu/tamawarai_menu_top.jpg"),
};

export const harajukuTerraceTerraceImage = {
  src: require("../../public/images/media/harajuku/terrace/terrace.jpg"),
  preSrc: require("../../public/images/media/harajuku/terrace/terrace.jpg"),
};

export const harajukuTheGreatBurgerSelectionTopImage = {
  src: require("../../public/images/media/harajuku/the_great_burger_selection/top.jpeg"),
  preSrc: require("../../public/images/media/harajuku/the_great_burger_selection/top.jpeg"),
};

export const harajukuTheGreatBurgerAppearanceInTelephoneImage = {
  src: require("../../public/images/media/harajuku/the_great_burger/appearance_in_telephone.jpg"),
  preSrc: require("../../public/images/media/harajuku/the_great_burger/appearance_in_telephone.jpg"),
};

export const harajukuTheGreatBurgerAppearanceInImage = {
  src: require("../../public/images/media/harajuku/the_great_burger/appearance_in.png"),
  preSrc: require("../../public/images/media/harajuku/the_great_burger/appearance_in.png"),
};

export const harajukuTheGreatBurgerApperanceOutImage = {
  src: require("../../public/images/media/harajuku/the_great_burger/apperance_out.jpeg"),
  preSrc: require("../../public/images/media/harajuku/the_great_burger/apperance_out.jpeg"),
};

export const harajukuTheGreatBurgerBugerImage = {
  src: require("../../public/images/media/harajuku/the_great_burger/buger.jpg"),
  preSrc: require("../../public/images/media/harajuku/the_great_burger/buger.jpg"),
};

export const harajukuTheGreatBurgerOwnerImage = {
  src: require("../../public/images/media/harajuku/the_great_burger/owner.jpg"),
  preSrc: require("../../public/images/media/harajuku/the_great_burger/owner.jpg"),
};

export const harajukuTheGreatBurgerStaffImage = {
  src: require("../../public/images/media/harajuku/the_great_burger/staff.jpg"),
  preSrc: require("../../public/images/media/harajuku/the_great_burger/staff.jpg"),
};

export const harajukuTheGreatBurgerTopImage = {
  src: require("../../public/images/media/harajuku/the_great_burger/top.jpg"),
  preSrc: require("../../public/images/media/harajuku/the_great_burger/top.jpg"),
};

export const harajukuUraharaHarajukuUraharaLunchImage = {
  src: require("../../public/images/media/harajuku/urahara/harajuku_urahara_lunch.jpg"),
  preSrc: require("../../public/images/media/harajuku/urahara/harajuku_urahara_lunch.jpg"),
};

export const harajukuUrthcafeTopImage = {
  src: require("../../public/images/media/harajuku/urthcafe/top.jpg"),
  preSrc: require("../../public/images/media/harajuku/urthcafe/top.jpg"),
};

export const harajukuUzunaomomMenuUzunaMenuAlcholImage = {
  src: require("../../public/images/media/harajuku/uzunaomom_menu/uzuna_menu_alchol.jpg"),
  preSrc: require("../../public/images/media/harajuku/uzunaomom_menu/uzuna_menu_alchol.jpg"),
};

export const harajukuUzunaomomMenuUzunaMenuTeaImage = {
  src: require("../../public/images/media/harajuku/uzunaomom_menu/uzuna_menu_tea.jpg"),
  preSrc: require("../../public/images/media/harajuku/uzunaomom_menu/uzuna_menu_tea.jpg"),
};

export const harajukuUzunaomomMenuUzunaMenuTopImage = {
  src: require("../../public/images/media/harajuku/uzunaomom_menu/uzuna_menu_top.jpg"),
  preSrc: require("../../public/images/media/harajuku/uzunaomom_menu/uzuna_menu_top.jpg"),
};

export const harajukuUzunaomomTopImage = {
  src: require("../../public/images/media/harajuku/uzunaomom/top.jpg"),
  preSrc: require("../../public/images/media/harajuku/uzunaomom/top.jpg"),
};

export const harajukuVegetablesHarajukuLunchSaladImage = {
  src: require("../../public/images/media/harajuku/vegetables/harajuku_lunch_salad.jpg"),
  preSrc: require("../../public/images/media/harajuku/vegetables/harajuku_lunch_salad.jpg"),
};

export const harajukuWosyokuHarajukuWashokuImage = {
  src: require("../../public/images/media/harajuku/wosyoku/harajuku_washoku.jpg"),
  preSrc: require("../../public/images/media/harajuku/wosyoku/harajuku_washoku.jpg"),
};

export const harajukuYogoroMenuYogoroMenuTopImage = {
  src: require("../../public/images/media/harajuku/yogoro_menu/yogoro_menu_top.jpg"),
  preSrc: require("../../public/images/media/harajuku/yogoro_menu/yogoro_menu_top.jpg"),
};

export const harajukuYogoroMenuYogoroMenuImage = {
  src: require("../../public/images/media/harajuku/yogoro_menu/yogoro_menu.jpg"),
  preSrc: require("../../public/images/media/harajuku/yogoro_menu/yogoro_menu.jpg"),
};

export const harajukuYogoroYogoroTopImage = {
  src: require("../../public/images/media/harajuku/yogoro/yogoro_top.jpg"),
  preSrc: require("../../public/images/media/harajuku/yogoro/yogoro_top.jpg"),
};

export const mediaNakameguroSekiyaInsideImage = {
  src: require("../../public/images/media/nakameguro/sekiya_inside.jpg"),
  preSrc: require("../../public/images/media/nakameguro/sekiya_inside.jpg"),
};

export const mediaNakameguroSekiyaLineImage = {
  src: require("../../public/images/media/nakameguro/sekiya_line.jpg"),
  preSrc: require("../../public/images/media/nakameguro/sekiya_line.jpg"),
};

export const mediaNakameguroSekiyaNapolitanImage = {
  src: require("../../public/images/media/nakameguro/sekiya_napolitan.jpg"),
  preSrc: require("../../public/images/media/nakameguro/sekiya_napolitan.jpg"),
};

export const mediaSearchTopImage = {
  src: require("../../public/images/media/search/top.jpg"),
  preSrc: require("../../public/images/media/search/top.jpg"),
};

export const shinjukuGyokaiRamenShinjukuRamenTopImage = {
  src: require("../../public/images/media/shinjuku/gyokai_ramen/shinjuku_ramen_top.jpg"),
  preSrc: require("../../public/images/media/shinjuku/gyokai_ramen/shinjuku_ramen_top.jpg"),
};

export const shinjukuKaijinKaijinTopImage = {
  src: require("../../public/images/media/shinjuku/kaijin/kaijin_top.jpg"),
  preSrc: require("../../public/images/media/shinjuku/kaijin/kaijin_top.jpg"),
};

export const tagsTagTopImage = {
  src: require("../../public/images/media/tags/tag/top.jpg"),
  preSrc: require("../../public/images/media/tags/tag/top.jpg"),
};

export const mediaTopImage = {
  src: require("../../public/images/media/top.jpg"),
  preSrc: require("../../public/images/media/top.jpg"),
};

export const privacyPolicyTopImage = {
  src: require("../../public/images/privacy_policy/top.jpg"),
  preSrc: require("../../public/images/privacy_policy/top.jpg"),
};
