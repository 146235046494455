import React from "react";
import Head from "next/head";
import LazyLoad from "react-lazyload";
import { ImagePlaceholder, OptimizedImage } from "@src/@types/image";

interface Props {
  img: string | OptimizedImage;
  alt: string;
  placeholder?: ImagePlaceholder;
  style?: React.CSSProperties;
}

export default function LazyImage({ img, alt, placeholder, style }: Props) {
  if (placeholder == "lqip") {
    placeholder = (
      <img
        src={typeof img === "string" ? img : img.preSrc}
        alt={alt}
        style={{ width: "100%", borderRadius: 5, ...style }}
      />
    );
  }
  return (
    <>
      <Head>
        <style
          dangerouslySetInnerHTML={{
            __html: ".lazyload-wrapper { height: 100% }",
          }}
        />
      </Head>
      <LazyLoad once offset={500} placeholder={placeholder}>
        <img
          src={typeof img === "string" ? img : img.src}
          alt={alt}
          style={{ width: "100%", borderRadius: 5, ...style }}
        />
      </LazyLoad>
    </>
  );
}
